import React, { useCallback, useContext, useState } from 'react';
import cn from 'classnames';

import Tracking from '../services/Tracking';

import { SupportModal, type SupportModalProps } from './Modals/SupportModal';
import T, { TProvider, type TProviderProps } from './Translate';

import styles from './WizardNavigation.module.scss';

export interface WizardNavigationContext {
  backButton?: string;
  translationPrefix?: TProviderProps['prefix'];
  translationNamespace?: TProviderProps['ns'];
  translationContext?: TProviderProps['context'];
  translationSeparator?: TProviderProps['prefixSeparator'];
  supportModal?: SupportModalProps;

  onReturnClick?(): void | Promise<void>;

  onSkip?(): void | Promise<void>;
}

const context = React.createContext<WizardNavigationContext>(null);

export function WizardNavigationProvider({ children, ...props }: React.PropsWithChildren<WizardNavigationContext>) {
  return <context.Provider value={props}>{children}</context.Provider>;
}

interface Props extends Partial<WizardNavigationContext> {
  hideBackButton?: boolean;
  hideSupportModal?: boolean;
  showSkipButton?: boolean;
  pageID?: string;

  onReturnClick?(): void | Promise<void>;

  onSkip?(): void | Promise<void>;
}

export default function WizardNavigation(props: Props) {
  const ctx = useContext(context);
  const [opened, setOpened] = useState(false);

  const handleClick = useCallback(async () => {
    Tracking.track('WIZARD_BACK_CLICKED', props.pageID);
    if (props.onReturnClick) {
      await props.onReturnClick();
    } else if (ctx?.onReturnClick) {
      await ctx?.onReturnClick();
    }
  }, [props.onReturnClick, ctx]);

  const handleSkip = useCallback(async () => {
    Tracking.track('WIZARD_SKIP', props.pageID);
    if (props.onSkip) {
      await props.onSkip();
    } else if (ctx?.onSkip) {
      await ctx?.onSkip();
    }
  }, [props.onSkip, ctx]);

  const handleSupportClick = useCallback(() => {
    Tracking.track('WIZARD_SUPPORT_CLICKED', props.pageID);
    setOpened(true);
  }, [props.pageID]);

  if (props.hideBackButton && props.hideSupportModal) {
    return null;
  }

  const tProviderProps: TProviderProps = {
    context: props.translationContext ?? ctx?.translationContext,
    prefix: props.translationPrefix ?? ctx?.translationPrefix,
    ns: props.translationNamespace ?? ctx?.translationNamespace,
    prefixSeparator: props.translationSeparator ?? ctx?.translationSeparator,
  };

  const supportModalProps: SupportModalProps = props.supportModal || ctx?.supportModal;

  return (
    <TProvider {...tProviderProps}>
      <div
        className={cn(styles.container, {
          [styles.withBackButton]: !props.hideBackButton,
          [styles.withSupportButton]: !props.hideSupportModal,
          [styles.withSkipButton]: props.showSkipButton,
        })}
      >
        {!props.hideBackButton ? (
          (props.backButton ?? ctx?.backButton) ? (
            <button className={styles.backButton} onClick={handleClick} tabIndex={-1} type="button">
              <i className="icon icon-arrow-left icon-sm" />
              <T id={props.backButton ?? ctx?.backButton} />
            </button>
          ) : (
            <button className={styles.backArrow} onClick={handleClick} tabIndex={-1} type="button">
              <i className="icon icon-arrow-back" />
            </button>
          )
        ) : null}
        {!props.hideSupportModal ? (
          <>
            <button className={styles.supportButton} onClick={handleSupportClick} tabIndex={-1} type="button">
              <T id="global_support" />
            </button>
            <SupportModal {...supportModalProps} opened={opened} setOpened={setOpened} />
          </>
        ) : (
          <div />
        )}
        {props.showSkipButton ? (
          <>
            <button className={styles.backArrow} onClick={handleSkip} tabIndex={-1} type="button">
              <T id="onboarding.skip" />
            </button>
          </>
        ) : null}
      </div>
    </TProvider>
  );
}
